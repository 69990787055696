const fragmentShaderReplacements = [
  {
    from: "#include <common>",
    to: /*glsl*/ `
              #include <common>           
              
              
              uniform float uMixFactor;
              uniform float uMixCouche;
              uniform float uTime;
              uniform float uOpacity;
              uniform float uEmissiveIntensity;
  
              uniform vec3 uEmissive;
              uniform vec3 uDiffuse;
              uniform vec3 uShade;
              uniform vec3 uActiveColor;
              uniform vec3 uPreviousColor; 
  
              uniform vec2 uMouseUV;
              uniform float uMouseActive;

  
              // uniform sampler2D uCouche;
              
              uniform sampler2D uMaterialite;
              uniform sampler2D uIconographie;
                  
              vec3 multiply (vec3 target, vec3 blend) { 
                  return target*blend;
              }
  
  
  
              vec3 difference (vec3 target, vec3 blend){
                return abs (target - blend);              
              }
  
              vec3 exclusion (vec3 target, vec3 blend){
                return 0.5 - 2.0*(target-0.5)*(blend-0.5);              
              }      
           
              vec3 subtract (vec3 target, vec3 blend){
                return target - blend;
              }
        
              vec3 divide (vec3 target, vec3 blend){
                return target/blend;   
              }
  
              vec3 screen (vec3 target, vec3 blend){
                return 1.0 - (1.0 - target) * (1.0 - blend);
            }
  
  
            float blendSubstract(float base, float blend) {
              return max(base+blend-1.0,0.0);
            }
            
            vec3 blendSubstract(vec3 base, vec3 blend) {
              return max(base+blend-vec3(1.0),vec3(0.0));
            }
            
            vec3 blendSubstract(vec3 base, vec3 blend, float opacity) {
              return (blendSubstract(base, blend) * opacity + blend * (1.0 - opacity));
            }
  
            vec3 blendNegation(vec3 base, vec3 blend) {
              return vec3(1.0)-abs(vec3(1.0)-base-blend);
            }
            
            vec3 blendNegation(vec3 base, vec3 blend, float opacity) {
              return (blendNegation(base, blend) * opacity + base * (1.0 - opacity));
            }
  
  
            
            vec3 clear(vec3 target, vec3 blend){

              vec3 light = vec3(1.0);
              vec3 shade = vec3(0.65); 
             
              if 
              (            
                (blend.r  ==  target.r) 
                &&
                (blend.g  ==  target.g)
                &&
                (blend.b  ==  target.b)            
              )
                { 
                  return light;
                  // return mix(light, shade, pct);
                }         
              else 
    
              {
                return shade;
                // return vec3(0.65);
                // return mix(vec3(1.0), vec3(0.7), pct);
  
              }
            }
            
            // vec3 transition(vec3 old, vec3 new ){
  
  
            // }
  
      vec4 sRGBToLinear( in vec4 value ) {
          return vec4( mix( pow( value.rgb * 0.9478672986 + vec3( 0.0521327014 ), vec3( 2.4 ) ), value.rgb * 0.0773993808, vec3( lessThanEqual( value.rgb, vec3( 0.04045 ) ) ) ), value.a );
      }
         
             `,
  },

  {
    from: "#include <color_pars_fragment>",
    to: /*glsl*/ `
              #include <color_pars_fragment>
              varying vec2 vUv;
            `,
  },

  {
    from: "#include <color_fragment>",
    to: /*glsl*/ `
              #include <color_fragment>        
              

              
              vec4 materialite = texture2D( uMaterialite, vUv ); 
              vec4 iconographie = texture2D( uIconographie, vUv );  

              vec3 white = vec3(1.0);
              vec3 black = vec3(0.0);
              
              



              // vec3 coucheActive = mix(materialite.rgb, iconographie.rgb, uMixCouche); 
              vec3 coucheActive;              
              if(uMixCouche == 1.0){
                 coucheActive = iconographie.rgb;
              } else {
                 coucheActive = materialite.rgb;
              }; 


              // vec3 currentPochage = mix(uActiveColor,clear(coucheActive.rgb, uActiveColor), uMouseActive);
              vec3 currentPochage;
      
              if (uMouseActive == 1.0){

                // pour eviter que la transparence en white soit interpretee

                if(uActiveColor == white || uActiveColor == black){
                  currentPochage = white;
                } else {
                  currentPochage = clear(coucheActive.rgb, uActiveColor);
                }

                //  currentPochage = clear(coucheActive.rgb, uActiveColor);


              } else { 
                currentPochage = white;
              };

              // vec3 previousPochage = mix(uPreviousColor,clear(coucheActive.rgb, uPreviousColor), uMouseActive); 
              vec3 previousPochage ;
  
              if (uMouseActive == 1.0){


                //  pour eviter que la transparence en white soit interpretee
                if(uPreviousColor == white || uPreviousColor == black){
                  previousPochage = white;
                } else {
                  previousPochage = clear(coucheActive.rgb, uPreviousColor);
                }

              //  previousPochage = clear(coucheActive.rgb, uPreviousColor);


              } else { 
                previousPochage = white;
              };



              // vec3 mixPochage = mix(previousPochage,currentPochage, uOpacity );
              vec3 mixPochage = currentPochage;// en attendant mieux 
              
              diffuseColor.rgb = mix(multiply(mixPochage,diffuseColor.rgb),diffuseColor.rgb, uMixFactor);
              // diffuseColor.rgb = previousPochage;

            `,
  },

  {
    from: "#include <emissivemap_pars_fragment>",
    to: /*glsl*/ `
              #include <emissivemap_pars_fragment>  
              #ifdef USE_EMISSIVEMAP
  
                    uniform sampler2D uEmissiveMap;
  
              #endif    
            `,
  },
  {
    from: "#include <emissivemap_fragment>",
    to: /*glsl*/ `
              #include <emissivemap_fragment>  
              #ifdef USE_EMISSIVEMAP  
              vec4 newEmissiveColor = texture2D( uEmissiveMap, vUv );  
              vec3 newEmissiveRGB = newEmissiveColor.rgb;                  
              vec3 result = difference(uActiveColor, newEmissiveRGB);                                    
    
              totalEmissiveRadiance *= result;
  
              #endif    
            `,
  },
];

export default fragmentShaderReplacements;
