import React from "react";
import { useParams } from "react-router-dom";
import { Route, Router } from "wouter";

import useHashLocation from "../../hooks/useHashLocation";

import Fragment from "./fragment";

const env = import.meta.env.VITE_NODE_ENV;

const WouteFragment = () => {
  const params = useParams();
  const { numero } = params;

  return (
    <Route path={`/fragments/${numero}`} nest>
      <Fragment numero={numero} />
    </Route>
  );
};

const RouteProd = () => {
  return (
    <Router hook={useHashLocation}>
      <WouteFragment />
    </Router>
  );
};

const RouteDev = () => {
  return (
    <Router>
      <WouteFragment />
    </Router>
  );
};

const routes = {
  development: RouteDev,
  production: RouteProd,
};

const CurrentRoute = routes[env];

const RouteFragment = () => {
  const params = useParams();
  const { numero } = params;

  return <CurrentRoute />;
};

export default RouteFragment;
