import { Routes, Route, Link, useLocation, redirect } from "react-router-dom";
import Canvas3d from "./components/viewer/canvas3D";
import Canvas3d_sansLeva from "./components/viewer/canvas3D_sansLeva";
import { Couches, Code } from "./components/chapitres/couches";

import Tholos3D from "./components/chapitres/tholos/tholos3D";
import { Fragments } from "./components/chapitres/fragments";
import { Fragment } from "./components/chapitres/fragment";
import RouteFragment from "./components/chapitres/fragment/routeFragment";
import { Palette, Palettes } from "./components/chapitres/palettes";
import Thesaurii from "./components/chapitres/thesaurii/thesaurii";
import ThesaurusRouter from "./components/chapitres/thesaurii/thesaurusRouter";
import Thesaurus from "./components/chapitres/thesaurii/thesaurus";
import Concept from "./components/chapitres/thesaurii/concept";
import Home from "./components/chapitres/home/home";
import Admin from "./components/chapitres/admin/admin";
import Element from "./components/chapitres/admin/element";
import Outils from "./components/chapitres/outils/outils";

import { Galerie } from "./components/chapitres/galerie";

const Views = () => {
  const couches = ["materialite", "iconographie"];

  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/admin">
        <Route index element={<Admin />} />
        <Route path=":numero" element={<Element />} />
      </Route>
      <Route path="/tholos">
        <Route index element={<Tholos3D />} />
        <Route path=":station" element={<Tholos3D />} />
        {/* <Route path=":parent/:station" element={<Tholos3D />} /> */}
      </Route>
      <Route path="/objets">
        <Route path=":id" element={<div>objet 1</div>} />
      </Route>
      <Route path="/fragments">
        <Route index element={<Fragments />} />
        {couches.map((r, i) => (
          <Route key={i} path={r} element={<Couches couche={r} />} />
        ))}
        {couches.map((r, i) => (
          <Route key={i} path={`${r}/:code`} element={<Code couche={r} />} />
        ))}

        {/* <Route path=":numero" element={<RouteFragment />} /> */}
        <Route path=":numero">
          <Route index element={<RouteFragment />} />
          <Route path=":section">
            <Route index element={<RouteFragment />} />
            <Route path=":soussection" element={<RouteFragment />} />
          </Route>
        </Route>

        {/* <Route path=":numero" element={<Fragment />} /> */}
      </Route>
      <Route path="/outils" element={<Outils />} />
      <Route path="/palettes">
        <Route index element={<Palettes />} />
        <Route path=":definition" element={<Palette />} />
        <Route path="*" element={<div>Pas de palette associée</div>} />
      </Route>
      {/* <Route path="/fragment">
        <Route path=":numero" element={<Fragment />} />
        <Route path="*" element={<div>Pas de fragment</div>} />
      </Route> */}

      <Route path="/galerie" element={<Galerie />} />
      <Route path="/viewer">
        <Route index element={<Canvas3d />} />
      </Route>
      {/* <Route path="/thesaurus" element={<Thesaurus />}></Route> */}
      <Route path="/thesaurii">
        <Route index element={<Thesaurii />} />
        <Route path=":theme" element={<ThesaurusRouter />} />
        <Route path=":theme/:concept" element={<Concept />} />
        <Route path="definition/:concept" element={<Concept />} />
        <Route path="*" element={<div>Pas de thème associé</div>} />
      </Route>
      <Route path="/viewer_sansLeva">
        <Route index element={<Canvas3d_sansLeva />} />
      </Route>
      <Route path="*" element={<div>404 page non trouvée</div>} />
    </Routes>
  );
};
export default Views;
