import React, { useState, forwardRef } from "react";
import Acrotere from "./acrotere";
import Label from "../ui/label";

const Acroteres = forwardRef((props, ref) => {
  const { handleClick, name, geometry, ...others } = props;
  const [hovered, setHovered] = useState(null);

  const handlePointerOver = (e) => {
    setHovered(true);
  };

  const handlePointerOut = (e) => {
    setHovered(false);
  };

  hovered
    ? (document.body.style.cursor = "pointer")
    : (document.body.style.cursor = "default");

  return (
    <group
      ref={ref}
      name={name}
      onPointerOver={handlePointerOver}
      onPointerOut={handlePointerOut}
      onClick={handleClick}
    >
      {[...Array(20)].map((item, index) => (
        <Acrotere
          key={index}
          name={`acrotere_${index + 1}`}
          collection={name}
          rotation={[0, (Math.PI / 10) * (index + 1), 0]}
          // rotation={[0, (Math.PI / 20) * (index + 1), 0]}
          hovered={hovered}
          url={`/modeles/tholos/${geometry}.glb`}
          geometry={geometry}
          {...others}
        ></Acrotere>
      ))}
    </group>
  );
});

export default Acroteres;
