const xmlParserOptions = {
  ignoreAttributes: false,
  // ignoreNameSpace: true,
  trim: true,
  // parseAttributeValue: true,
  stopNodes: ["*.p", "*.head", "*.bibl"],
  processEntities: true,
  isArray: (name, jpath, isLeafNode, isAttribute) => {
    if (["children", "div", "p", "bibl"].indexOf(name) !== -1) return true;
  },
  // htmlEntities: true,
};

export default xmlParserOptions;
