import React, { useRef, useEffect, useState, useMemo } from "react";
import * as THREE from "three";
import { Canvas, useThree } from "@react-three/fiber";
import { Perf } from "r3f-perf";

import { useProgress } from "@react-three/drei";

import { Route } from "wouter";
import Woutes from "../../routes/woutes";

import {
  FragmentDummy,
  FragmentPreview,
  FragmentModel,
  MyEffects,
  MyEnvironment,
  MyLights,
} from "./index";

import Annotations from "./annotations/annotations";
import FragmentAnnotationCard from "./ui/fragmentAnnotationCard";

import Overlay from "../../ui/loader/overlay";

const loadingBarElement = document.querySelector(".loading-bar");

const CanvasFragment = (props) => {
  const {
    name,
    width,
    fitToSphere,
    couche,
    rotationFragment,
    annotationsOn,
    setOpenNotice,
    openNotice,
    isFragment,
    isModele,
    numero,
  } = props;

  const [count, setCount] = useState(0);
  const progress = useProgress((state) => state.progress);
  const item = useProgress((state) => state.item);

  let ratio = 0;
  let score = 0;
  // useEffect(() => {
  //   ratio = progress / 100;
  //   loadingBarElement.classList.remove("ended");
  //   loadingBarElement.style.transform = `scaleX(${ratio})`;

  //   ratio >= 1
  //     ? window.setTimeout(() => {
  //         loadingBarElement.classList.add("ended"),
  //           (loadingBarElement.style.transform = "");
  //       }, 500)
  //     : null;

  //   // progress >= 100
  //   //   ? window.setTimeout(() => {
  //   //       (loadingBarElement.style.transform = "scaleX(0)"),
  //   //         // (loadingBarElement.style.transformOrigin = "top right"),
  //   //         //(loadingBarElement.style.transition ="transform 1.5s ease-in-out");

  //   //         loadingBarElement.classList.add("ended"),
  //   //         (loadingBarElement.style.transform = "");
  //   //     }, 250)
  //   //   : (loadingBarElement.classList.remove("ended"),
  //   //     (loadingBarElement.style.transform = `scaleX(${ratio})`));
  //   // // (loadingBarElement.style.transformOrigin = "top left"),
  //   // loadingBarElement.style.transition = "transform 0.5s";
  // }, [progress]);

  const MyScene = () => {
    const { scene, camera } = useThree();

    scene.name = `scene_${name}`;
  };

  return (
    <div
      className="webgl"
      id="scene3D-fragment"
      style={{ width: `calc(100% - ${width}px)` }}
    >
      {/* <div
        style={{
          width: "350px",
          position: "absolute",
          zIndex: 10000,
        }}
      >
        <FragmentAnnotationCard />
      </div> */}
      <Canvas
        flat
        legacy={true}
        linear={true}
        shadows
        dpr={[1, 2]}
        camera={{
          fov: 20,
          near: 0.01,
          far: 40,
          position: [0, 0, 0.33],
        }}
        // frameloop="demand"
      >
        {/* <Perf position="bottom-right" /> */}
        {/* <MyEffects /> */}
        {/* {isFragment ? <Overlay /> : null} */}

        <MyScene />
        <MyLights />

        {isFragment && isModele ? (
          <>
            <FragmentModel
              filename={name}
              fitToSphere={fitToSphere}
              couche={couche}
              rotationFragment={rotationFragment}
              annotationsOn={annotationsOn}
              setOpenNotice={setOpenNotice}
              openNotice={openNotice}
            />
            {/* <Annotations /> */}
          </>
        ) : (
          <FragmentDummy filename={name} />
        )}
        {/* <MyEnvironment /> */}
      </Canvas>
    </div>
  );
};
export default CanvasFragment;
