import React, { useState, useEffect } from "react";

import useHistoryBack from "../hooks/useHistoryBack";

import Header from "./header/header";
import ModalTocText from "./modalTocText/modalTocTex";
import DrawerToc from "./drawerToc/drawerToc";

const UI = () => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const back = useHistoryBack();

  return (
    <>
      <Header handleOpen={handleOpen} />
      <ModalTocText open={open} handleClose={handleClose} />
      {/* <DrawerToc open={open} handleClose={handleClose} /> */}
    </>
  );
};

export default UI;
