import React, {
  memo,
  useMemo,
  useState,
  useRef,
  forwardRef,
  useEffect,
  useCallback,
} from "react";
import * as THREE from "three";
import { useFrame, createPortal } from "@react-three/fiber";
import { useFBO } from "@react-three/drei";

import { vertexShader, fragmentShader } from "../../../shaders/renderTarget";
import { setTextures } from "./setTextures";
import getCode from "./getCode";
import { RepartitionRounded } from "@mui/icons-material";
import useDefinitionStore from "../../../stores/useDefinitionStore";

const RenderTarget = forwardRef(function RenderTarget(props, ref) {
  const { id, map, couche, mouseActive, mouse, pointerDown, maps } = props;

  // const size = maps[couche].source.data.height;
  const size = map.source.data.height;

  const [scene] = useState(() => new THREE.Scene());
  scene.name = `RenderTarget_${id}`;
  const camera = new THREE.OrthographicCamera();
  camera.position.z = 1;

  const meshRef = useRef();
  const matRef = useRef();

  const renderTarget = useFBO(size, size, {
    minFilter: THREE.NearestFilter,
    magFilter: THREE.NearestFilter,
    format: THREE.RGBAFormat,
    type: THREE.UnsignedByteType, // avec new Uint8Array(4)
    generateMipmaps: false,
    colorSpace: THREE.SRGBColorSpace,
  });

  const noticeActive = useDefinitionStore((state) => state.noticeActive);

  const pixel = useMemo(() => {
    return { value: new Uint8Array(4) };
  });

  const previousPixel = useRef(new Uint8Array(4));
  const color = new THREE.Color();
  const activeColorRef = useRef(new THREE.Color());
  const previousColorRef = useRef(new THREE.Color());

  function normalize(value, min, max) {
    return (value - min) / (max - min);
  }

  useFrame((state, delta) => {
    const { gl, clock } = state;

    if (mouseActive) {
      if (!noticeActive && id === couche) {
        if (!pointerDown) {
          const { x, y } = mouse.value;

          let cursor = {
            x: Math.floor(size * x) + 0.5,
            y: Math.floor(size * (1 - y)) + 0.5,
          };

          camera.setViewOffset(size, size, cursor.x, cursor.y, 1, 1);
          gl.setRenderTarget(renderTarget);
          gl.clear();
          gl.render(scene, camera);
          gl.readRenderTargetPixels(renderTarget, 0, 0, 1, 1, pixel.value);
          let [r, g, b, a] = pixel.value;
          let [R, G, B] = [r, g, b].map((comp) => normalize(comp, 0, 255));

          activeColorRef.current.fromArray([R, G, B]);

          if (
            activeColorRef.current.r !== previousColorRef.current.r ||
            activeColorRef.current.g !== previousColorRef.current.g ||
            activeColorRef.current.b !== previousColorRef.current.b
          ) {
            getCode(activeColorRef.current, previousColorRef.current, couche);
          }

          previousColorRef.current.copy(activeColorRef.current);

          gl.setRenderTarget(null);
        }
      }
    }
  });

  const uniforms = useMemo(
    () => ({
      uCouche: { value: map },
      uColor: { value: activeColorRef.current },
      uNoticeActive: { value: noticeActive ? 1.0 : 0.0 },
    }),
    [couche, pointerDown]
  );

  return (
    <>
      {createPortal(
        <mesh name="RenderTarget_mesh" ref={ref}>
          <planeGeometry args={[2, 2, 2, 2]} name="RenderTarget_geom" />
          <shaderMaterial
            ref={matRef}
            name="RenderTarget_mat"
            fragmentShader={fragmentShader}
            vertexShader={vertexShader}
            uniforms={uniforms}
            needsUpdate={true}
          />
        </mesh>,
        scene
      )}
    </>
  );
});

export default RenderTarget;
