import React, { useState, useEffect } from "react";
import { IconButton } from "@mui/material";

import { XMLParser } from "fast-xml-parser";

import CircleIcon from "@mui/icons-material/Circle";
import CircleOutlinedIcon from "@mui/icons-material/CircleOutlined";

import { convertNotation } from "../../../../outils";

import useDefinitionStore from "../../../../stores/useDefinitionStore";

const FragmentRadioAnnotation3D = (props) => {
  const backgroundColor = "#FFFFFF66";
  const backgroundColorError = "#FF000099";

  const { handleActiveButton, activeButton, index, annotation } = props;

  const [hovered, setHovered] = useState(false);
  const [xml, setXml] = useState(null);

  const setActiveColor = useDefinitionStore((state) => state.setActiveColor);
  const setMouseActive = useDefinitionStore((state) => state.setMouseActive);
  const setCurrentCouche = useDefinitionStore(
    (state) => state.setCurrentCouche
  );

  let hex = annotation.index?.[`@_rendition`]?.split(" ")?.pop();
  let color = hex ? backgroundColor : backgroundColorError;

  useEffect(() => {
    if (hovered) {
      setMouseActive(true);
      let res;
      hex
        ? ((res = convertNotation(hex)),
          setCurrentCouche(res.couche),
          setActiveColor(res.hex, res.couche))
        : null;

      // console.log(convertNotation(annotation?.[`@_rendition`]));
    } else {
      setMouseActive(false);
    }
  }, [hovered]);

  return (
    <IconButton
      title={hex ? hex : "Erreur dans le formatage TEI"}
      aria-label={hex ? hex : "Erreur dans le formatage TEI"}
      size="small"
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      onClick={() => handleActiveButton(index)}
    >
      {/* {hovered ? <CircleIcon /> : <CircleOutlinedIcon />} */}
      {activeButton === index ? (
        <CircleIcon
          sx={{
            color: color,
          }}
        />
      ) : hovered ? (
        <CircleIcon
          sx={{
            color: color,
          }}
        />
      ) : (
        <CircleOutlinedIcon
          sx={{
            color: color,
          }}
        />
      )}
    </IconButton>
  );
};

export default FragmentRadioAnnotation3D;
