import React, { useEffect, useState } from "react";
import useQueriesConcept from "./useQueriesConcept";
import { XMLParser } from "fast-xml-parser";

const parserOptions = {
  trim: true,
};
const parser = new XMLParser();

const useParseConcept = (idc) => {
  const params = { idc: idc, idt: "th653" };

  const [concept, setConcept] = useState();

  const { data, isLoading, isError, isSuccess } = useQueriesConcept(params);

  useEffect(() => {
    if (data) {
      let parsedData = parser.parse(data, parserOptions)[`rdf:RDF`][
        `rdf:Description`
      ];
      setConcept({
        id: idc,
        definition: parsedData[`skos:definition`],
        label: parsedData[`skos:preflabel`],
        notation: parsedData[`skos:notation`],
      });
    }
  }, [isSuccess]);

  return { isSuccess, concept };
};

export default useParseConcept;
