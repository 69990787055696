import { Canvas, useThree } from "@react-three/fiber";
import {
  useProgress,
  CameraControls,
  BakeShadows,
  AccumulativeShadows,
  RandomizedLight,
} from "@react-three/drei";
import { Link } from "react-router-dom";
import { useRoute, useLocation, Route, Router } from "wouter";

import { Suspense, useEffect, useRef, useMemo, memo } from "react";

import useHashLocation from "../../hooks/useHashLocation";

import useUiStore from "../../../stores/useUiStore";

import Woutes from "../../routes/woutes";

import Tholos from "./tholos";
import PointCloud from "./pointcloud";
import Lights from "./lights";
import Ground from "./ground";
import Shadows from "./shadows";
import Fragments from "./fragments";

import { CustomLoader } from "../../model";

const RouteScene = () => {
  const { scene } = useThree();
  scene.name = "Accueil Tholos";

  const paramsTholos = useUiStore((state) => state.paramsTholos);

  return (
    <Route path="/tholos" nest>
      <Ground />
      <Tholos />
      {!paramsTholos.reconstitution.value &&
      !paramsTholos.anastyloseSite.value ? (
        <Shadows />
      ) : null}

      <Fragments />
      {paramsTholos.pointcloud.value ? <PointCloud /> : null}

      <Lights />
    </Route>
  );
};

const env = import.meta.env.VITE_NODE_ENV;

const RouteProd = () => {
  return (
    <Router hook={useHashLocation}>
      <RouteScene />
    </Router>
  );
};

const RouteDev = () => {
  return (
    <Router>
      <RouteScene />
    </Router>
  );
};

const routes = {
  development: RouteDev,
  production: RouteProd,
};

const CurrentRoute = routes[env];

const TholosCanvas = () => {
  return (
    <div className="webgl">
      <Canvas
        name="Tholos3D"
        //id="scene3D"
        shadows
        camera={{
          fov: 35,
          near: 0.05,
          far: 150,
          position: [0, 35, 0],
        }}
        // flat
        dpr={[1, 2]}
      >
        <Suspense fallback={<CustomLoader />}>
          <CurrentRoute />
          {/* <Woutes content={<RouteScene />} /> */}
          {/* <Woutes>
            <RouteScene />
          </Woutes> */}
        </Suspense>
      </Canvas>
    </div>
  );
};

export default TholosCanvas;
