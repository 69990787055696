export const stations = [
  {
    id: "",
    title: "",
    position: [17, 1.6, 17],
    // target: [0, 4, 0],
    target: [0, 4.75, 0],
    maxDistance: 50,
    minDistance: 20,
  },
  {
    id: "tholos",
    title: "tholos",
    position: [17, 1.8, 17],
    target: [0, 4, 0],
  },
  {
    id: "monument",
    title: "monument",
    position: [17, 1.8, 17],
    target: [0, 4, 0],
  },
  {
    id: "grandes-metopes",
    title: "Grandes Métopes",
    position: [8, 5, 8],
    target: [0, 10, 0],
    maxDistance: 17.86,
    minDistance: 9,
    directionDistance: 1.8,
  },
  {
    id: "petites-metopes",
    title: "Petites Métopes",
    position: [4, 5.75, 4],
    target: [0, 8.5, 0],
    maxDistance: 13.36,
    minDistance: 5.5,
    directionDistance: 1.4,
  },
  {
    id: "acroteres",
    title: "Acrotères",
    position: [11, 6, 11],
    target: [0, 10, 0],
    maxDistance: 20,
    minDistance: 9,
    directionDistance: 2,
  },
  {
    id: "metope-cheval-cabre",
    title: "Métope au cheval cabré",
    position: [6.65, 7, 4.5],
    target: [5.65, 7, 3.5],
    maxDistance: 20,
    minDistance: 9,
  },
  {
    id: "metope-colonne",
    title: "Métope à la colonne",
    position: [5.48, 6.25, 6.42],
    target: [4.29, 7, 5.02],
    maxDistance: 20,
    minDistance: 9,
  },
  {
    id: "metope-croupe-chevaline",
    title: "Métope à la croupe chevaline",
    position: [6.42, 6.24, 5.49],
    target: [5.02, 7, 4.29],
    maxDistance: 20,
    minDistance: 9,
  },
  {
    id: "metope-pied-droit",
    title: "Métope au pied droit",
    position: [4.41, 6.24, 7.2],
    target: [3.45, 7, 5.63],
    maxDistance: 20,
    minDistance: 9,
  },
  {
    id: "grande-metope",
    title: "Grande métope",
    maxDistance: 6,
    minDistance: 1,
    polarAngleCoef: 0.25,
    azimuthAngleCoef: 0.25,
  },
  {
    id: "petite-metope",
    title: "Petite métope",
    maxDistance: 1.5,
    minDistance: 0.5,
    polarAngleCoef: 0.25,
    azimuthAngleCoef: 0.25,
  },
  {
    id: "ordres",
    title: "Ordres",
    position: [17, 1.8, 17],
    target: [0, 4, 0],
    maxDistance: 50,
    minDistance: 20,
  },
];
