import React from "react";
import { ToggleButton } from "@mui/material";
import BlockIcon from "@mui/icons-material/Block";

import useModelStore from "../../../../stores/useModelStore";

const ButtonReset = () => {
  const { filter, setFilter } = useModelStore();
  const handleChange = (e) => {
    setFilter({ gltf: null, tei: null });
  };
  return (
    <ToggleButton
      color="primary"
      title="réinitialiser"
      value="zéro"
      size="small"
      onChange={handleChange}
    >
      <BlockIcon color="primary" />
    </ToggleButton>
  );
};

export default ButtonReset;
