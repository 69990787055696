import React, { useState, useEffect, useRef } from "react";
import { Grid, Box } from "@mui/material";

import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Section3 from "./section3";
import Head from "./head";

import Paragraphe from "./paragraphe";
import Gallery from "./gallery";
import GallerySection3 from "./gallerySection3";

import { isArray } from "../../../outils";

const Section2Accordion = (props) => {
  const { handleChange, expanded } = props;

  // props.head && props.head === "Annotations 3D"
  //   ? console.log(props.div[0])
  //   : null;

  return (
    <Accordion
      key={props[`@_xml:id`]}
      id={props[`@_xml:id`]}
      type={props[`@_type`]}
      defaultExpanded={true}
      // expanded={expanded === props[`@_xml:id`]}
      // onChange={handleChange(props[`@_xml:id`])}
    >
      <AccordionSummary>
        {props.head ? <Head text={props.head} variant="overline" /> : null}
      </AccordionSummary>

      {props.head && props.head === "Identifiants UDs 3D" && (
        <AccordionDetails>
          <Gallery images={props.p} />
        </AccordionDetails>
      )}

      {/* {props.head && props.div && props.head === "UD 2D" ? (
        <AccordionDetails>
          {props.div.map((div, id) => (
            <Section3 key={id} {...div} />
          ))}
        </AccordionDetails>
      ) : null} */}
      {props.head && props.div && props.head === "UD 2D" ? (
        <AccordionDetails>
          <GallerySection3 images={props.div} />
        </AccordionDetails>
      ) : null}

      {props.head !== "Identifiants UDs 3D" && props.head !== "UD 2D" ? (
        <AccordionDetails>
          {props.p
            ? props.p.map((paragraphe, i) => (
                <Paragraphe
                  key={paragraphe[`@_xml:id`]}
                  text={paragraphe[`#text`]}
                  id={paragraphe[`@_xml:id`]}
                />
              ))
            : null}
        </AccordionDetails>
      ) : null}

      {props.head && props.head === "Annotations 3D" ? (
        <AccordionDetails>
          {props.div
            ? props.div.map((d, i) => (
                <Box key={i}>
                  <Paragraphe text={d.head} id={d[`@_xml:id`]} />
                  {d.p ? (
                    <Paragraphe
                      key={d.p[0][`@_xml:id`]}
                      text={d.p[0][`#text`]}
                      id={d.p[0][`@_xml:id`]}
                    />
                  ) : null}
                </Box>
              ))
            : null}
        </AccordionDetails>
      ) : null}

      {/* {props.head && props.head === "Identifiants UDs 3D" ? (
        <AccordionDetails>
          <Gallery paragraphe={props.p} />
        </AccordionDetails>
      ) : (
        <AccordionDetails>
          {props.p
            ? props.p.map((paragraphe, i) => (
                <Paragraphe
                  key={paragraphe[`@_xml:id`]}
                  text={paragraphe[`#text`]}
                  id={paragraphe[`@_xml:id`]}
                />
              ))
            : null}
          {props.div
            ? props.div.map((div, id) => <Section3 key={id} {...div} />)
            : null}
        </AccordionDetails>
      )} */}
    </Accordion>
  );
};

export default Section2Accordion;
