import React, { useState, useEffect } from "react";

const useLoadImage = (props) => {
  const [image, setImage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();

  useEffect(() => {
    setLoading(true);
    const img = new Image();
    img.src = props.image;
    img.onload = () => {
      setLoading(false);
      setImage(props.image);
    };

    img.onerror = () => {
      setLoading(false);
      setError("Error loading image");
    };
  }, [props.image]);

  return { image, loading, error };
};

export default useLoadImage;
