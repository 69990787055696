import React, { useState } from "react";
import { useQuery } from "@tanstack/react-query";

const useQueriesThesaurus = (thesaurus, fn) => {
  const status = import.meta.env.VITE_OPENTHESO_STATUS;

  const adress = {
    online: {
      path: "https://opentheso.huma-num.fr/opentheso/api/",
      url: `expansion/concept?theso=th653&id=${thesaurus}&way=down`,
    },
    offline: {
      path: "/opentheso/",
      url: `th653_id${thesaurus}_waydown.rdf`,
    },
  };

  const view = adress[status];

  return useQuery({
    queryKey: [`opentheso_${thesaurus}`],
    queryFn: async () => {
      const res = await fetch(`${view.path}${view.url}`);

      if (!res.ok) {
        throw new Error("pb côté serveur");
      }

      return res.text();
    },

    refetchOnWindowFocus: false,
    staleTime: 1000 * 60 * 10,
    onSuccess: fn,
  });
};
export default useQueriesThesaurus;
