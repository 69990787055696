import React, { useEffect, useRef, useState } from "react";
import { useRoute, useLocation } from "wouter";
import { useThree } from "@react-three/fiber";
import { useQueries } from "@tanstack/react-query";
import useDefinitionStore from "../../stores/useDefinitionStore";

const usePosition = (cameraControlsRef, numero, annotations) => {
  const [match, params] = useRoute("/:section/:id");
  const [location, setLocation] = useLocation();
  const [isFirstLoad, setIsFirstLoad] = useState(true);

  const myRoute = useRef(params);
  const clickRef = useRef();

  let loc = location.split("/");

  return useEffect(() => {
    console.log("hit");
    function placeCamera(annotation) {
      useDefinitionStore.setState({ mouseActive: false });

      let posTar = [...annotation.position, ...annotation.target];
      cameraControlsRef.setLookAt(...posTar, true);
    }

    if (loc.length === 3 && cameraControlsRef) {
      switch (loc[1]) {
        case "annotations":
          // console.log(loc[2]);
          annotations && annotations?.annotations3D?.[loc[2]]
            ? placeCamera(annotations.annotations3D[loc[2]])
            : null;
          break;
        case "materialite":
          console.log("materialite");
          break;
        case "iconographie":
          console.log("iconographie");
          break;
        default:
          break;
      }
    }
  }, [location, match, isFirstLoad]);
};

export default usePosition;
