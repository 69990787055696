import React, { useState, useEffect, useLayoutEffect } from "react";
import { Tooltip } from "@mui/material";

const TooltipNote = (props) => {
  const { id, note, data } = props;

  return (
    <Tooltip id={id} title={data} arrow leaveDelay={250}>
      <sup className="note">&#8239;{note}</sup>
    </Tooltip>
  );
};

export default TooltipNote;
