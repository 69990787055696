import React, { useState, useEffect } from "react";
import { Box, Typography, Stack, Grid, Button } from "@mui/material";

import { useRoute, useLocation, Route, Router } from "wouter";

import * as jp from "jsonpath";

import useStyledXML from "../../hooks/useStyledXML";
import { truncateSentence } from "../../../outils";

import Paragraphe from "../notices/paragraphe";

const TexteGeneral = (props) => {
  const { id } = props;
  const [text, setText] = useState("");
  const txt = useStyledXML("texteGeneral", "tableOfContent");

  let params = { id: id };

  useEffect(() => {
    if (params?.id && txt) {
      let [res] = jp.query(txt, `$..[?(@.subtype=="${params?.id}")]`);

      setText(res);
    }
  }, [params?.id, txt]);

  return (
    <Stack
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
      }}
    >
      {/* <Typography variant="h6">{text?.head}</Typography> */}
      <Typography
        variant="h6"
        component="div"
        dangerouslySetInnerHTML={{ __html: text?.head }}
      />

      {/* <div dangerouslySetInnerHTML={{ __html: text?.head }} /> */}
      {/* <Stack direction="column"> */}
      {/* <Grid container>
          <Grid item xs={5}>
            {text?.p?.map((p, i) => (
              <Paragraphe key={i} id={p.id} text={p[`#text`]} />
            ))}
          </Grid>
        </Grid> */}
      <Box
        style={{
          columnCount: 3,
          columnFill: "auto",
          columnGap: "30px",
          height: `calc(100% - 100px)`,
        }}
      >
        {text?.p?.map((p, i) => (
          <Paragraphe key={i} id={p.id} text={p[`#text`]} />
        ))}
      </Box>
      <Stack direction="row" spacing={2}>
        {text?.div &&
          text?.div.map((div, i) => (
            <Button
              key={i}
              size="small"
              // onClick={(e) => handleClick(e, div.subtype)}
            >
              {truncateSentence(div.head, 4)}
            </Button>
          ))}
      </Stack>
    </Stack>
  );
};

export default TexteGeneral;
