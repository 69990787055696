import React, { useRef } from "react";
import { useGLTF } from "@react-three/drei";

export default function Fleuron2(props) {
  const group = useRef();
  const { nodes, materials } = useGLTF("/modeles/tholos/fleuron_v2.glb");

  return (
    <group ref={group} {...props} dispose={null} name="fleuron">
      <mesh
        name="fleuron"
        castShadow
        receiveShadow
        geometry={nodes.fleuron_v2.geometry}
        material={materials.marbre}
        position={[0, 0, 0]}
      />
    </group>
  );
}
