import React, { useEffect, useState, useRef, Suspense } from "react";
import { useParams, Link, useNavigate } from "react-router-dom";

import { useRoute } from "wouter";
import useModelStore from "../../../stores/useModelStore";
import useDefinitionStore from "../../../stores/useDefinitionStore";
import useQueries from "../../hooks/useQueries";
import useQueriesAPI from "../../hooks/useQueriesAPI";

import NoticeDrawer from "../notices/ui/noticeDrawer";

import { checkIfUrlExists, checkIfFileExists } from "../../../outils";

import CanvasFragment from "./canvasFragment";
import CanvasCouche from "./canvasCouche";
import FragmentFabs from "./ui/fragmentFabs";
import FragmentSettingsCard from "./ui/fragmentSettingCard";
import FragmentTabs from "./ui/fragmentTabs";
import FragmentAnnotationCard from "./ui/fragmentAnnotationCard";
import FragmentRadioAnnotations3D from "./ui/fragmentRadioAnnotations3D";
import Entete from "./ui/entete";

const Fragment = (props) => {
  const [, params] = useRoute("/:section/:annotation");

  const { numero } = props;
  const navigate = useNavigate();

  const [openSettings, setOpenSettings] = useState(false);
  const [openNotice, setOpenNotice] = useState(false);
  const [fitToSphere, setFitToSphere] = useState(false);
  const [annotationsOn, setAnnotationsOn] = useState(false);
  const [hasModele, setHasModele] = useState(false);

  const currentCouche = useDefinitionStore((state) => state.currentCouche);
  const setCurrentCouche = useDefinitionStore(
    (state) => state.setCurrentCouche
  );

  const couche = {
    materialite: "matérialité",
    iconographie: "iconographie",
  };

  const {
    data: fragment,
    isSuccess,
    isLoading,
    isError,
  } = useQueriesAPI(`fragments/${numero}`);

  let isFragment;

  fragment?.gltf && fragment?.gltf_5k
    ? (isFragment = true)
    : (isFragment = false);

  const width = 800;

  /**
   *  HANDLERS
   */

  const handleSettings = (value) => (e) => {
    setOpenSettings(value);
  };

  const handleNotice = () => {
    setOpenNotice(!openNotice);
  };

  const handleFitToSphere = () => {
    setFitToSphere(!fitToSphere);
  };

  const handleTabs = (e, newValue) => {
    setCurrentCouche(newValue);
  };

  const toogleAnnotations = () => {
    setAnnotationsOn(!annotationsOn);
  };

  /**
   * EFFECTS
   *
   */

  useEffect(() => {
    if (isError) {
      setTimeout(() => {
        navigate("/fragments");
      }, 2000);
    }
  });

  useEffect(() => {
    let filename = numero.split(",").join("-");
    let isModele = checkIfUrlExists(`/modeles/${filename}/${filename}.glb`);
    setHasModele(isModele);
  }, []);

  /**
   *
   *
   *
   */

  return isSuccess && fragment?.numero_inventaire_3d && hasModele ? (
    <>
      <div
        id="reglages-fragments"
        sx={{ visibility: openSettings ? "visible" : "hidden" }}
      >
        <FragmentSettingsCard
          open={openSettings}
          handleSettings={handleSettings}
        />
      </div>

      <FragmentAnnotationCard openNotice={openNotice} width={width} />

      <NoticeDrawer
        open={openNotice}
        setOpenNotice={setOpenNotice}
        width={width}
        fragment={fragment?.numero_inventaire_3d}
      />

      {/* <CanvasCouche /> */}

      <Entete
        numero={numero}
        sousEntete={couche[currentCouche]}
        isSousEntete={!openNotice}
      />

      <CanvasFragment
        name={fragment.numero_inventaire_3d}
        numero={numero}
        rotationFragment={isFragment ? fragment.rotation_xyz : null}
        width={openNotice ? width : 0}
        fitToSphere={fitToSphere}
        handleFitToSphere={handleFitToSphere}
        setFitToSphere={setFitToSphere}
        setOpenNotice={setOpenNotice}
        openNotice={openNotice}
        couche={currentCouche}
        annotationsOn={annotationsOn}
        isFragment={isFragment}
        isModele={hasModele}
      />

      <footer>
        <div className="footer">
          <span className="alignLeft">
            <FragmentFabs
              handleSettings={handleSettings}
              handleFitToSphere={handleFitToSphere}
              toogleAnnotations={toogleAnnotations}
              setOpenNotice={setOpenNotice}
              openNotice={openNotice}
              annotationsOn={annotationsOn}
            />
          </span>

          <span className="alignRight"></span>
        </div>
      </footer>

      {!openNotice ? (
        <>
          <FragmentRadioAnnotations3D
            fragment={fragment.numero_inventaire_3d}
          />
          <FragmentTabs handleChange={handleTabs} value={currentCouche} />
        </>
      ) : null}
    </>
  ) : isLoading ? (
    <Entete numero={numero} sousEntete="chargement ..." isSousEntete={true} />
  ) : (
    <Entete
      numero={numero}
      sousEntete="ce numéro n'est pas attribué"
      isSousEntete={true}
    />
  );
};

export default Fragment;
