import React, { useState, useEffect } from "react";
import { XMLParser } from "fast-xml-parser";
import xmlParserOptions from "../chapitres/notices/common/xmlParserOptions";

import useQueriesNotice from "./useQueriesNotice";

const useParseNotice = (fragment, parserOptions) => {
  const [xml, setXml] = useState(null);

  const parser = new XMLParser(parserOptions || xmlParserOptions);

  const { data, isLoading, isError, isSuccess } = useQueriesNotice(fragment);

  function parseData(data) {
    return parser.parse(data);
  }

  useEffect(() => {
    if (data) {
      setXml(parseData(data));
    }
  }, [isSuccess]);

  return { isSuccess, isLoading, isError, xml };
};

export default useParseNotice;
