import React from "react";

import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import AutorenewOutlinedIcon from "@mui/icons-material/AutorenewOutlined";
import { domToReact } from "html-react-parser";

import Vignette from "../ui/vignette";
import TooltipArchimageIIIF from "../ui/tooltipArchimageIIIF";

import { checkUrl, checkIfUrlExists } from "../../../../outils";

const options = {
  replace(domNode) {
    let { attribs, children, name } = domNode;

    if (!attribs) {
      return;
    }

    if (name === "ref") {
      if (attribs.target && checkUrl(attribs.target)) {
        let url = attribs.target.split("//")[1];
        let root = url.split("/");
        let domain = url.split(".")[0];
        switch (domain) {
          case "archimage": {
            let urlParams = new URLSearchParams(attribs.target.split("?")[1]);
            let params = Object.fromEntries(urlParams);

            if (params)
              switch (params.kroute) {
                case "fiche_publique": {
                  let imgId = parseInt(params.id);
                  let imgUrl = `https://archimage.efa.gr/action.php?kroute=image_preview_public&id=${imgId}&type=2&ext=.jpg`;
                  let ficheUrl = `https://archimage.efa.gr/?kroute=fiche_publique&id=${imgId}`;
                  let afficheUrl = `https://archimage.efa.gr/?kroute=afficher_modele_3d&id=${imgId}`;
                  return (
                    <Vignette
                      route={ficheUrl}
                      image={imgUrl}
                      icon={<OpenInNewIcon />}
                      content={domToReact(children, options)}
                      archimageId={imgId}
                      alt="Afficher dans Archimage"
                      target="_blank"
                      color="primary"
                    />
                  );
                }
                case "fiche_document": {
                  console.log(params);
                  return (
                    <a href={attribs.target} target="_blank">
                      <img
                        src={`https://archimage.efa.gr/action.php?kroute=image_preview_public&id=${parseInt(
                          params.id
                        )}&type=2&ext=.jpg`}
                        width="150"
                      />
                    </a>
                  );
                }
                case "recuperer_modele_3d": {
                  let imgId = parseInt(params.id);
                  let imgUrl = `https://archimage.efa.gr/action.php?kroute=image_preview_public&id=${imgId}&type=2&ext=.jpg`;
                  let ficheUrl = `https://archimage.efa.gr/?kroute=fiche_publique&id=${imgId}`;
                  let afficheUrl = `https://archimage.efa.gr/?kroute=afficher_modele_3d&id=${imgId}`;
                  return (
                    <Vignette
                      route={afficheUrl}
                      image={imgUrl}
                      icon={<OpenInNewIcon />}
                      content={domToReact(children, options)}
                      archimageId={imgId}
                      alt="Afficher dans Archimage"
                      target="_blank"
                      color="primary"
                    />
                  );
                }
                case "image_request_iiif": {
                  // switch(){

                  // }
                  console.log(attribs);
                  return (
                    <TooltipArchimageIIIF
                      id={params.id}
                      text={domToReact(children, options)}
                      target={attribs.target}
                    />
                  );
                }
              }
            if (root[1] === "image_request_iiif") {
              let imgId = parseInt(root[2]);
              let region = root[3].split(",").map(Number);
              let [x, y, w, h] = region;
              let newRegion = [x, y, w, w].map(String);
              let size = root[4].split(",").map(Number);
              let [width, height] = size;
              let ratio = h / w;
              let resolution = 220;
              let newSize =
                ratio > 1
                  ? [Math.round(resolution / ratio, 0), resolution]
                  : [resolution, Math.round(resolution * ratio, 0)];
              let rotation = root[5];
              let quality = root[6].split(".")[0];
              let format = root[6].split(".")[1];

              switch (attribs?.type) {
                case "archimage_IIIF":
                  let imgUrl = `https://${root[0]}/index.php?kroute=${root[1]}&id=${imgId}&region=${region}&size=${newSize}&rotation=${rotation}&quality=default&format=jpg`;
                  let ficheUrl = `https://archimage.efa.gr/?kroute=fiche_publique&id=${imgId}`;

                  return (
                    <Vignette
                      route={ficheUrl}
                      image={imgUrl}
                      icon={<OpenInNewIcon />}
                      content={imgId}
                      archimageId={imgId}
                      alt="Afficher dans Archimage"
                      target="_blank"
                      color="primary"
                    />
                  );
              }
            }

            break;
          }
          case "tholos": {
            let route = attribs.target.split("#")[1];

            switch (attribs?.type) {
              case "monument": {
                let elem = route.split("/");
                let last = elem[elem.length - 1];
                return (
                  <mark className="monument-3d">
                    {domToReact(children, options)}
                  </mark>
                );
              }
            }

            return (
              <Vignette
                route={route}
                icon={<AutorenewOutlinedIcon />}
                content={domToReact(children, options)}
                alt="Ouvrir la page du fragment"
                target="_self"
                color="success"
              />
            );
          }
        }
      }

      return (
        <span style={{ color: "#C2A074" }}>
          <a href={attribs.target} target="_blank">
            <mark
              style={{
                color: "#C2A074",
                backgroundColor: "hsla(0, 0%, 100%, 0)",
              }}
            ></mark>
          </a>
        </span>
      );
    }
  },
};

export default options;
