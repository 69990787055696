import React from "react";
import { ToggleButton } from "@mui/material";

import ViewInArIcon from "@mui/icons-material/ViewInAr";
import CodeIcon from "@mui/icons-material/Code";

import useModelStore from "../../../../stores/useModelStore";

const ButtonTEI = () => {
  const field = "tei";
  const filter = useModelStore((state) => state.filter);
  const setFilter = useModelStore((state) => state.setFilter);
  const handleChange = (e) => {
    setFilter({ ...filter, [field]: !filter[field] ? true : null });
  };
  return (
    <ToggleButton
      color="primary"
      title="TEI"
      value="tei"
      size="small"
      selected={filter[field] ? true : false}
      onChange={handleChange}
    >
      <CodeIcon color={filter[field] ? "primary" : "disabled"} />
    </ToggleButton>
  );
};

export default ButtonTEI;
