import React from "react";
import parse, { attributesToProps, domToReact } from "html-react-parser";
import { Typography } from "@mui/material";

const ReferenceBiblio = (props) => {
  const { text } = props;

  const options = {
    replace(domNode) {
      let { attribs, children, name } = domNode;

      if (!attribs) {
        return;
      }

      if (name === "seg") {
        let bibId = attribs[`xml:id`];
        return (
          <span>
            <a href={`#${bibId}`}>{domToReact(children, options)}</a>
          </span>
        );
      }

      if (name === "hi") {
        switch (attribs.rend) {
          case "small-caps":
            return (
              <span className="biblioRef">{domToReact(children, options)}</span>
            );
        }
      }
    },
  };

  let parsedText = parse(text, options);

  return (
    <Typography variant="body2" align="justify" paragraph>
      {parsedText}
    </Typography>
  );
};

export default ReferenceBiblio;
