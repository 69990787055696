import React, { useRef, useState } from "react";
import { useFrame } from "@react-three/fiber";
import { useGLTF } from "@react-three/drei";
import { DEG2RAD } from "three/src/math/MathUtils.js";
import { Color } from "three";

import useUiStore from "../../../../stores/useUiStore";

export default function FragmentDecor(props) {
  const { decor_ID, name, position, rotation } = props;

  const paramsTholos = useUiStore((state) => state.paramsTholos);

  const [hovered, setHovered] = useState(null);

  const handlePointerOver = (e) => {
    // e.stopPropagation();
    // setHovered(true);
  };

  const handlePointerOut = (e) => {
    // e.stopPropagation();
    // setHovered(false);
  };

  hovered
    ? (document.body.style.cursor = "pointer")
    : (document.body.style.cursor = "default");

  const pathToModel = `/modeles/${name}/5K/${name}.glb`;
  const pathToDraco = `/draco/`;

  const model = useGLTF(pathToModel, pathToDraco);

  const group = useRef();
  const matRef = useRef();
  const { nodes } = model;

  let geometryName = nodes[name] ? name : `${name}_5K`;

  const rotationRAD = rotation.map((r) => r * DEG2RAD);

  const type = !!decor_ID.split("-")[1]
    ? decor_ID.split("-")[0]
    : decor_ID.split("_")[0];
  const index = parseInt(decor_ID.split("_")[1]);

  const dim = {
    petite: {
      altitude: 6.26,
      hauteur: 0.431,
      radius: 3.915,
      shift: 8,
      fractions: [40, 20],
    },
    grande: {
      altitude: 6.694,
      hauteur: 0.651,
      radius: 6.59,
      shift: 7,
      fractions: [40, 20],
    },
    acrotere: {
      altitude: 8.25,
      hauteur: 0,
      radius: 6.55,
      shift: -1,
      // fractions: [4, 4],
      fractions: [10, 10],
    },
  };

  const decalageAngle = Math.PI / dim[type].fractions[0];
  const angle = Math.PI / dim[type].fractions[1];

  useGLTF.preload(pathToModel, pathToDraco);

  const grey = new Color("grey");
  const goldenrod = new Color("goldenrod");
  const marble = new Color(0xa29a99);

  useFrame((state, delta) => {
    matRef.current.color.lerp(hovered ? goldenrod : marble, 0.3);
  });

  return (
    <group
      ref={group}
      dispose={null}
      name={name}
      rotation={[0, decalageAngle + angle * (index + dim[type].shift), 0]}
      visible={paramsTholos.decor.value}
    >
      <group
        position={[
          0,
          dim[type].altitude + dim[type].hauteur / 2,
          dim[type].radius,
        ]}
        // onPointerOver={handlePointerOver}
        // onPointerOut={handlePointerOut}
      >
        <mesh
          name={`${name}_mesh`}
          castShadow
          receiveShadow
          geometry={nodes[geometryName].geometry}
          position={position}
          rotation={rotationRAD}
        >
          <meshStandardMaterial color={marble} ref={matRef} />
        </mesh>
      </group>
    </group>
  );
}
