import React, { useState, useMemo, useEffect, Suspense } from "react";
import Head from "./head";
import Section2 from "./section2";
import Section2Accordion from "./section2Accordion";

import { isArray } from "../../../outils";

const Section1 = (props) => {
  const [expanded, setExpanded] = useState("");
  const handleChange = (panel) => (e, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  // const views = {
  //   accordion: Section2Accordion,
  //   gallery: Section2,
  // };

  return (
    <div key={props[`@_xml:id`]} id={props[`@_xml:id`]} type={props[`@_type`]}>
      {/* {props.head ? <Head text={props.head} variant="overline" /> : null} */}

      {props.div
        ? props.div.map((section, index) => (
            <Section2Accordion
              key={section[`@_xml:id`]}
              {...section}
              handleChange={handleChange}
              expanded={expanded}
            />
          ))
        : null}
    </div>
  );
};

export default Section1;
