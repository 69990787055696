import { create } from "zustand";
import { subscribeWithSelector } from "zustand/middleware";
import { mountStoreDevtool } from "simple-zustand-devtools";

import { XMLParser } from "fast-xml-parser";
import parserOptions from "../components/chapitres/thesaurii/common/parserOptions";

const parser = new XMLParser(parserOptions);

const useThesoStore = create(
  subscribeWithSelector((set) => ({
    index: { materialite: [], iconographie: [] },
    theso: [],
    isLoaded: false,
    setTheso: (theso) => set((state) => ({ ...state, theso })),
    setIsLoaded: (isLoaded) => set((state) => ({ ...state, isLoaded })),
    opentheso: [],
    thesaurii: { materialite: [], iconographie: [] },
  }))
);

let openthesoURL;
let thesaurus;

let apiURL = "https://opentheso.huma-num.fr/opentheso/api";
let idt = "th653";

let thesaurusOnline = [
  {
    id: 1,
    label: "iconographie",
    url: `${
      import.meta.env.VITE_OPENTHESO_API
    }/expansion/concept?theso=${idt}&id=1&way=down`,
  },
  {
    id: 2,
    label: "materialite",
    url: `${
      import.meta.env.VITE_OPENTHESO_API
    }/expansion/concept?theso=${idt}&id=2&way=down`,
  },
];

let thesaurusOffline = [
  {
    id: 1,
    label: "iconographie",
    // url: "/xml/iconographie.xml",
    url: "/opentheso/th653_id1_waydown.rdf",
  },
  {
    id: 2,
    label: "materialite",
    // url: "/xml/materialite.xml",
    url: "/opentheso/th653_id2_waydown.rdf",
  },
];

switch (import.meta.env.VITE_OPENTHESO_STATUS) {
  case "online":
    openthesoURL = `${
      import.meta.env.VITE_OPENTHESO_API
    }/all/theso?id=${idt}&format=rdf`;
    thesaurus = thesaurusOnline;
    break;
  case "offline":
    // openthesoURL = "/xml/th653.xml";
    openthesoURL = "/opentheso/th653.rdf";
    thesaurus = thesaurusOffline;
    break;
}

fetch(openthesoURL)
  .then((res) => res.text())
  .then((text) => parser.parse(text))
  .then((data) => data.RDF.Description)
  .then((data) => data.filter((c) => c.identifier))

  .then((data) =>
    useThesoStore.setState((state) => ({ ...state, opentheso: data }))
  );

thesaurus.map((th) =>
  fetch(th.url)
    .then((res) => res.text())
    .then((text) => parser.parse(text))
    .then((data) => data.RDF.Description)
    .then((data) => data.filter((c) => c.identifier))
    .then((data) =>
      useThesoStore.setState((state) => ({
        ...state,
        thesaurii: { ...state.thesaurii, [`${th.label}`]: data },
        index: {
          ...state.index,
          [`${th.label}`]: data.map((d) => d.identifier[`#text`]),
        },
      }))
    )
);

if (import.meta.env.VITE_NODE_ENV === "development") {
  mountStoreDevtool("ThesoStore", useThesoStore);
}

export default useThesoStore;
