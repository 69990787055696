import React, { useEffect, useState, useMemo } from "react";
import useQueriesThesaurus from "./useQueriesThesaurus";
import { XMLParser } from "fast-xml-parser";
import parserOptions from "../chapitres/thesaurii/common/parserOptions";

const parser = new XMLParser(parserOptions);

const useParseThesaurus = (idc) => {
  const [thesaurus, setThesaurus] = useState();

  const { data, isLoading, isError, isSuccess } = useQueriesThesaurus(idc);

  function parseData(data) {
    return parser.parse(data).RDF.Description.filter((c) => c.identifier);
  }

  useEffect(() => {
    if (data) {
      setThesaurus(parseData(data));
    }
  }, [isSuccess, idc]);

  return { isSuccess, isLoading, isError, thesaurus };
};

export default useParseThesaurus;
