import React, { Suspense, useState, useEffect } from "react";
import { Card, CardMedia, CardActions, Button, Skeleton } from "@mui/material";
import { checkIfUrlExists } from "../../../../outils";

import useQueries from "../../../hooks/useQueries";
import useLoadImage from "../../../hooks/useLoadImage";

const Vignette = (props) => {
  const { route, children, alt, icon, content, color, target } = props;

  const { image, error } = useLoadImage(props);

  const img_privee = "/images/img_privee.jpg";
  const img_blank = "/modeles/placeholders/geometry_blank.png";

  const { data, isSuccess } = useQueries(
    `https://archimage.efa.gr/action.php?kroute=document_export_json_public&id=${props.archimageId}`
  );

  const MyCardMedia = () => {
    return (
      <CardMedia
        sx={{
          width: "110px",
          height: "110px",
        }}
        component="img"
        image={props.image ? (error ? img_privee : image) : img_blank}
      />
    );
  };

  return (
    <Card sx={{ backgroundColor: "rgba(255,255,255,.15)" }}>
      <div
        style={{
          position: "relative",
        }}
      >
        <MyCardMedia />

        <CardActions sx={{ root: "absolute" }}>
          <Button
            size="small"
            href={route}
            startIcon={icon}
            alt={alt}
            target={target}
            color={color}
          >
            {isSuccess && props.archimageId ? data[`Référence`] : content}
          </Button>
        </CardActions>
      </div>
    </Card>
  );
};

export default Vignette;
