export const fragmentsGM = [
  {
    decor_ID: "grande-metope_36",
    name: "4183-b",
    title: "Métope au pied droit",
    position: [0, -0.365, 0.07],
    rotation: [0, 0, 0],
  },
  {
    decor_ID: "grande-metope_39",
    name: "4226",
    title: "Métope à la colonne",
    position: [0, 0, 0.057],
    rotation: [0, 0, 0],
  },
  {
    decor_ID: "grande-metope_2",
    name: "2856",
    title: "Métope à la croupe chevaline",
    position: [-0.2, 0.11, -0.001],
    rotation: [0, 0, 0],
  },
  {
    decor_ID: "grande-metope_37",
    name: "12677",
    title: "Amazone tirant de l'arc",
    position: [0, 0.03, 0.025],
    rotation: [0, 0, 0],
  },
  {
    decor_ID: "grande-metope_38",
    name: "3168",
    title: "Métope au cheval cabré",
    position: [0.0, 0, 0.07],
    rotation: [0, 0, 0],
  },
  {
    decor_ID: "grande-metope_1",
    name: "4183-a",
    title: "Métope à la pelta",
    position: [0.18, -0.01, 0.025],
    rotation: [0, 0, 0],
  },
  {
    decor_ID: "grande-metope_35",
    name: "4476-11",
    title: "Métope au duel amazonomachique",
    position: [0, -0.05, 0.08],
    rotation: [0, 0, 0],
  },
];
