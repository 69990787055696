import React from "react";
import { Text } from "@react-three/drei";

const FragmentDummy = (props) => {
  const { filename } = props;
  return (
    <group>
      <mesh
        // ref={octaMeshRef}
        name={`${filename}_octahedron`}
        visible={true}
        rotateY={Math.PI / 2}
      >
        <octahedronGeometry args={[0.04, 1]} />
        <meshBasicMaterial wireframe transparent opacity={0.25} />
      </mesh>
      {/* <Text>{filename}</Text> */}
    </group>
  );
};
export default FragmentDummy;
