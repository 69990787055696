import React from "react";
import * as THREE from "three";

import MyLight from "./myLight";

const MyLights = () => {
  return (
    <>
      <MyLight />
      <directionalLight intensity={0.15} position={[-0, -2, -1]} />
      <ambientLight intensity={0.5} />
    </>
  );
};

export default MyLights;
