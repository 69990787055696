import React, { useRef } from "react";
import { useGLTF } from "@react-three/drei";

export default function PlafondCaisson(props) {
  const group = useRef();
  const { nodes, materials } = useGLTF("/modeles/tholos/plafond_caisson.glb");

  return (
    <group>
      {[...Array(40)].map((item, index) => (
        <group
          key={index}
          ref={group}
          {...props}
          dispose={null}
          name={`plafond-caisson_${index + 1}`}
          rotation={[0, (Math.PI / 20) * (index + 1), 0]}
        >
          <mesh
            name={`plafond-caisson_${index + 1}_mesh`}
            castShadow
            receiveShadow
            geometry={nodes.plafond_caissons.geometry}
            material={materials.marbre}
            position={[0, 0, 0]}
          />
        </group>
      ))}
    </group>
  );
}

// useGLTF.preload("/modeles/tholos/couvrement.glb");
