import React from "react";
import { Box } from "@mui/material";

import parse from "html-react-parser";

import options from "./common/options";

export const GalleryAnnotation2D = (props) => {
  const { p } = props;

  const parsedText = parse(p[0][`#text`], options);

  return <Box sx={{ p: 1 }}>{p[0] && parsedText}</Box>;
};
