import React, { useState, useEffect } from "react";
import { ClickAwayListener } from "@mui/base";
import { Box, Stack, Fab, Badge, Tooltip } from "@mui/material";
import { useLocation } from "wouter";

import * as jp from "jsonpath";

import useParseNotice from "../../../hooks/useParseNotice";
import FragmentRadioAnnotation3D from "./fragmentRadioAnnotation3D";

const xmlParserOptions = {
  ignoreAttributes: false,
  // ignoreNameSpace: true,
  trim: true,
  // parseAttributeValue: true,
  stopNodes: [
    "*.p",
    // "*.head",
    "*.bibl",
    "*.index",
  ],
  processEntities: true,
  isArray: (name, jpath, isLeafNode, isAttribute) => {
    if (["children", "div", "p", "bibl", "hi"].indexOf(name) !== -1)
      return true;
  },
  // htmlEntities: true,
};

const FragmentRadioAnnotations3D = (props) => {
  const { fragment } = props;

  const [activeButton, setActiveButton] = useState(null);
  const [location, setLocation] = useLocation();

  const { isSuccess, xml } = useParseNotice(fragment, xmlParserOptions);

  let annotations;

  if (isSuccess && xml) {
    let annotations3D = jp.query(
      xml,
      `$..[?(@.@_type=="section2" && @.head=="Annotations 3D")]`
    )[0];

    // let result = annotations3D?.div.map(
    //   (d) => d.head.hi?.filter((i) => i.index)[0]?.index
    // );

    // let result = annotations3D?.div.map((d) => d.head).filter((h) => h.index);
    let result = annotations3D?.div.map((d) => d.head);

    // console.log(result);

    result?.length === 1 && result[0] === undefined
      ? (annotations = null)
      : (annotations = result);
  }

  const handleActiveButton = (value) => {
    setActiveButton(value);
    setLocation(`/annotations/annotation-${value + 1}`);
  };

  const handleClickAway = () => {
    console.log("click away");
    setActiveButton(null);
    // setLocation("");
  };

  return isSuccess && annotations?.length ? (
    <Box
      sx={{
        position: "absolute",
        top: "50%",
        right: 0,
        transform: `translateY(-50%)`,
        zIndex: 1000,
      }}
    >
      <ClickAwayListener onClickAway={handleClickAway}>
        <Stack
          direction="column"
          sx={{ mx: 2, "& > :not(style)": { m: 1 } }}
          // onClick={handleClickAway}
        >
          {annotations?.map((annotation, index) => {
            return (
              <FragmentRadioAnnotation3D
                key={index}
                index={index}
                activeButton={activeButton}
                annotation={annotation}
                handleActiveButton={handleActiveButton}
              />
            );
          })}
        </Stack>
      </ClickAwayListener>
    </Box>
  ) : null;
};

export default FragmentRadioAnnotations3D;
