import React from "react";
import { Stack } from "@mui/material";

import GalleryItem from "./galleryItem";

const Gallery = (props) => {
  const { images } = props;

  return (
    <Stack direction="row">
      {images &&
        images.map((img, i) => (
          <GalleryItem text={img[`#text`]} key={img[`@_xml:id`]} />
        ))}
    </Stack>
  );
};

export default Gallery;
