import React, { useRef, useEffect, useMemo, useState } from "react";
import { Drawer, Box, Typography, Stack, Avatar, Badge } from "@mui/material";
import { styled } from "@mui/material/styles";
import useModelStore from "../../../../stores/useModelStore";

import { multiFilter } from "../../../../outils";

import SearchField from "./searchField";
import Checks from "./checks";
import Button3d from "./button3d";
import ButtonTEI from "./buttonTEI";
import ButtonReset from "./buttonReset";
import ButtonClose from "./buttonClose";
import BadgeSearch from "./badgeSearch";

const MyDrawer = styled(Drawer)(() => ({
  "& .MuiModal-backdrop": {
    backgroundColor: "rgba(0,0,0,0.35)",
  },
}));

const BottomDrawer = (props) => {
  const { handleDrawer, open } = props;

  const SearchForm = () => {
    const { fragments, filter, setFilteredFragments } = useModelStore();

    const myFragments = useRef(fragments);

    let fields = [
      {
        field: "emplacement",
        headerName: "lieu de conservation",
        width: "210",
        title: "lieu de conservation au musée de Delphes",
      },
      {
        field: "emplacement_detail",
        headerName: "emplacement",
        width: "160",
        title: "tiroir, rangement, accrochage",
      },
      {
        field: "support_normalise",
        headerName: "support",
        width: "210",
        title: "type architectural",
      },
      {
        field: "sujet",
        headerName: "sujet",
        width: "260",
        title: "description iconographique",
      },
      {
        field: "numero_inventaire_apo",
        headerName: "n° d'inventaire",
        width: "150",
        title: "numéro d'inventaire du musée",
      },
    ];

    function setValueList(arr, value) {
      return arr
        .map((obj) => obj[value])
        .filter((item) => item)
        .reduce((acc, curr) => (acc.includes(curr) ? acc : [...acc, curr]), [])
        .sort();
    }

    return (
      <Stack
        direction="row"
        spacing={2}
        justifyContent="center"
        // alignItems="center"
      >
        {fields.map((item, index) => (
          <SearchField
            key={index}
            id={item.field}
            size="small"
            label={item.headerName}
            title={item.title}
            margin="none"
            width={item.width}
            field={item.field}
            // helperText=" "
            options={setValueList(
              multiFilter(myFragments.current, filter),
              item.field
            )}
          />
        ))}

        <Button3d />
        <ButtonTEI />
        <ButtonReset />
      </Stack>
    );
  };

  return (
    <MyDrawer
      anchor="bottom"
      open={open}
      onClose={handleDrawer(false)}

      // sx={{ backdrop: { color: "rgba(155,155,0,0.4)" } }}

      // hideBackdrop
    >
      <Box
        sx={{
          height: 40,
          m: 2,
        }}
      >
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Stack
            direction="row"
            spacing={1}
            // justifyContent="space-between"
            alignItems="center"
          >
            <BadgeSearch />
          </Stack>

          <SearchForm />
          <ButtonClose onClick={handleDrawer(false)} />
        </Stack>
      </Box>
    </MyDrawer>
  );
};

export default BottomDrawer;
