import React from "react";
import { Tabs, Tab } from "@mui/material";

const FragmentTabs = (props) => {
  const { value, handleChange } = props;
  const couches = [
    // { label: "base" },
    { label: "matérialité", value: "materialite" },
    { label: "iconographie", value: "iconographie" },
  ];
  return (
    <div
      style={{
        flexGrow: 1,
        position: "absolute",
        bottom: "0px",
        left: "50%",
        transform: "translate(-50%, -0%)",
        display: "inline",
        zIndex: 10,
      }}
    >
      <Tabs onChange={handleChange} value={value}>
        {couches.map((couche, index) => (
          <Tab key={index} label={couche.label} value={couche.value} />
        ))}
      </Tabs>
    </div>
  );
};

export default FragmentTabs;
