import React, { useState, useEffect, useMemo } from "react";
import {
  Card,
  CardContent,
  CardHeader,
  CardActions,
  Button,
  IconButton,
  ClickAwayListener,
  Typography,
  Link,
  Snackbar,
  Alert,
} from "@mui/material";

import ViewInArOutlinedIcon from "@mui/icons-material/ViewInArOutlined";
import HubOutlinedIcon from "@mui/icons-material/HubOutlined";

import GrainOutlinedIcon from "@mui/icons-material/GrainOutlined";
import ControlCameraOutlinedIcon from "@mui/icons-material/ControlCameraOutlined";

import useDefinitionStore from "../../../../stores/useDefinitionStore";
import useQueriesOpenTheso from "../../../hooks/useQueriesOpenTheso";
// import useQueriesThesaurus from "../../../hooks/useQueriesThesaurus";
import useParseConcept from "../../../hooks/useParseConcept";
import useParseThesaurus from "../../../hooks/useParseThesaurus";

const FragmentAnnotationCard = (props) => {
  const { openNotice, width } = props;

  // console.log(props);

  const [open, setOpen] = useState(false);

  const tooltip = useDefinitionStore((state) => state.tooltip);
  const setTooltip = useDefinitionStore((state) => state.setTooltip);
  const setMouseActive = useDefinitionStore((state) => state.setMouseActive);
  const setActiveColor = useDefinitionStore((state) => state.setActiveColor);

  const activeColor = useDefinitionStore((state) => state.activeColor);
  const currentCouche = useDefinitionStore((state) => state.currentCouche);

  const thesaurii = {
    iconographie: { id: 1, suffixe: "00", label: "Iconographie" },
    materialite: { id: 2, suffixe: "ff", label: "Materialité" },
  };

  const currentNotation = `#${tooltip.hex}${thesaurii[currentCouche].suffixe}`;

  const { thesaurus, isSuccess } = useParseThesaurus(
    thesaurii[currentCouche].id
  );

  const concept = useMemo(
    () =>
      thesaurus
        ?.filter((th) => th.notation)
        .find((th) => th.notation[`#text`] === currentNotation),
    [currentCouche, tooltip.hex, isSuccess]
  );

  const handleClickAway = () => {
    setTooltip(-500, -500, false);
  };

  const handlePointerEnter = (e) => {
    setMouseActive(true);
    setActiveColor(tooltip.hex, currentCouche);
  };

  const handleMouseLeave = () => {
    setTimeout(() => {
      setTooltip(-500, -500, false);
    }, 250);
  };

  const handleCopy = (e, item) => {
    navigator.clipboard.writeText(JSON.stringify(item));
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const offset = -5;

  return (
    isSuccess &&
    concept && (
      <div
        id="annotation-space"
        style={{
          zIndex: 1500,
          position: "relative",
          width: openNotice ? `calc(100% - ${width}px)` : `100%`,
          pointerEvents: "none",
        }}
      >
        <div
          id="annotation-card"
          onMouseLeave={handleMouseLeave}
          onMouseOver={handlePointerEnter}
          style={{
            position: "relative",
            maxWidth: "250px",
            pointerEvents: "auto",
            cursor: "pointer",
            top: `calc(${tooltip.y}px + ${offset}px)`,
            left: `calc(${tooltip.x}px + ${offset}px)`,
            visibility: tooltip.active ? "visible" : "hidden",
          }}
        >
          <ClickAwayListener onClickAway={handleClickAway}>
            <Card sx={{ backgroundColor: `rgb(0,0,0,0.25)` }} elevation={0}>
              <CardHeader
                title={concept?.prefLabel[`#text`] || tooltip.hex}
                subheader={thesaurii[currentCouche]?.label}
              />
              <CardContent>
                <Typography>
                  {concept?.definition &&
                  concept.definition[`#text`] != undefined
                    ? concept.definition[`#text`]
                    : "Pas de définition dans le thésaurus"}
                </Typography>
              </CardContent>
              <CardContent>
                <Typography color="primary">
                  <Link>Mes documents associés</Link>
                </Typography>
              </CardContent>
              <CardActions>
                {tooltip.annotation ? (
                  <Button
                    size="small"
                    startIcon={<ControlCameraOutlinedIcon />}
                    onClick={(e) => handleCopy(e, tooltip.annotation)}
                  >
                    Copier position
                  </Button>
                ) : null}
                {/* <Button size="small" startIcon={<GrainOutlinedIcon />}>
                Copier position
              </Button> */}
              </CardActions>
            </Card>
          </ClickAwayListener>
        </div>
        <Snackbar open={open} autoHideDuration={4000} onClose={handleClose}>
          <Alert onClose={handleClose} severity="info">
            Position copiée. Faites CTRL+V pour coller dans votre document.
          </Alert>
        </Snackbar>
      </div>
    )
  );
};

export default FragmentAnnotationCard;
