import React from "react";

const Entete = (props) => {
  const { numero, sousEntete, isSousEntete } = props;
  return (
    <>
      <div className="entete">n°{numero}</div>
      {isSousEntete ? <div className="sous-entete">{sousEntete}</div> : null}
    </>
  );
};

export default Entete;
