import React, { useEffect, useRef, useState } from "react";
import * as THREE from "three";
import { useGLTF, useAnimations, Html } from "@react-three/drei";
import { useFrame } from "@react-three/fiber";
import { useRoute } from "wouter";
import { useNavigate } from "react-router-dom";
import Label from "../ui/label";

export default function Acrotere(props) {
  const {
    name,
    hovered,
    handleLocalClick,
    collection,
    url,
    geometry,
    fragmentsAcroteres,
    title,
  } = props;
  const group = useRef();
  const matRef = useRef();
  const { nodes, materials } = useGLTF(url);

  const [localHovered, setLocalHovered] = useState();
  const [data, setData] = useState();

  const navigate = useNavigate();

  const onPointerOver = (e) => {
    if (match) {
      let myAcrotere = fragmentsAcroteres.find((f) => f.decor_ID === name);
      // console.log(name, myAcrotere);
      //   let myAcrotere = fragmentsAcroteres
      //     .find((f) => f.decor_ID)
      //     .includes(name);
      myAcrotere && setData(myAcrotere);
    }
    setLocalHovered(true);
  };
  const onPointerOut = (e) => {
    setLocalHovered(false);
  };

  const handleDoubleClick = (e) => {
    if (!data) {
      return;
    }

    navigate(`/fragments/${data?.name.replace("-", ",")}`);
  };

  const [match, params] = useRoute(`/?/${collection}`);

  const grey = new THREE.Color("grey");
  const goldenrod = new THREE.Color("goldenrod");
  const marble = new THREE.Color(0xa29a99);

  useFrame((state, delta) => {
    matRef.current.color.lerp(
      hovered && !match
        ? goldenrod
        : localHovered && match
        ? goldenrod
        : marble,
      0.3
    );
  });

  let visible = fragmentsAcroteres.map((f) => f.decor_ID).includes(name)
    ? false
    : true;

  // console.log(name, visible);

  useGLTF.preload(url);
  return (
    <group
      ref={group}
      {...props}
      dispose={null}
      onClick={handleLocalClick}
      onPointerOver={onPointerOver}
      onPointerOut={onPointerOut}
      onDoubleClick={handleDoubleClick}
    >
      <mesh
        name={`${name}_mesh`}
        transparent
        opacity={0.5}
        castShadow
        receiveShadow
        geometry={nodes[`${geometry}`].geometry}
        visible={true}
        position={[0, 8.25, 6.45]}
        scale={0.8}
      >
        <meshStandardMaterial
          name={`${name}_mat`}
          ref={matRef}
          transparent={true}
          opacity={visible ? 1 : 0.5}
          // visible={visible}
        />
      </mesh>
      {localHovered && match && data ? (
        <Label {...data} />
      ) : localHovered && !match ? (
        <Label name={name} title={title} />
      ) : null}
    </group>
  );
}
