import React from "react";

import { Box } from "@mui/material";

import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import AutorenewOutlinedIcon from "@mui/icons-material/AutorenewOutlined";
import parse, { attributesToProps, domToReact } from "html-react-parser";
// import options from "./common/options";

import Vignette from "./ui/vignette";
import TooltipArchimageIIIF from "./ui/tooltipArchimageIIIF";

import { checkUrl, checkIfUrlExists } from "../../../outils";

const GalleryItem = (props) => {
  const { text } = props;
  const options = {
    replace(domNode) {
      let { attribs, children, name } = domNode;

      if (!attribs) {
        return;
      }

      if (name === "ref") {
        if (attribs.target && checkUrl(attribs.target)) {
          let url = attribs.target.split("//")[1];
          let root = url.split("/");
          let domain = url.split(".")[0];
          switch (domain) {
            case "archimage": {
              let urlParams = new URLSearchParams(attribs.target.split("?")[1]);
              let params = Object.fromEntries(urlParams);

              if (params)
                switch (params.kroute) {
                  case "fiche_publique": {
                    let imgId = parseInt(params.id);
                    let imgUrl = `https://archimage.efa.gr/action.php?kroute=image_preview_public&id=${imgId}&type=2&ext=.jpg`;
                    let ficheUrl = `https://archimage.efa.gr/?kroute=fiche_publique&id=${imgId}`;
                    let afficheUrl = `https://archimage.efa.gr/?kroute=afficher_modele_3d&id=${imgId}`;
                    return (
                      <Vignette
                        route={ficheUrl}
                        image={imgUrl}
                        icon={<OpenInNewIcon />}
                        content={domToReact(children, options)}
                        alt="Afficher dans Archimage"
                        target="_blank"
                        color="primary"
                      />
                    );
                  }
                  case "fiche_document": {
                    console.log(params);
                    return (
                      <a href={attribs.target} target="_blank">
                        <img
                          src={`https://archimage.efa.gr/action.php?kroute=image_preview_public&id=${parseInt(
                            params.id
                          )}&type=2&ext=.jpg`}
                          width="150"
                        />
                      </a>
                    );
                  }
                  case "recuperer_modele_3d": {
                    let imgId = parseInt(params.id);
                    let imgUrl = `https://archimage.efa.gr/action.php?kroute=image_preview_public&id=${imgId}&type=2&ext=.jpg`;
                    let ficheUrl = `https://archimage.efa.gr/?kroute=fiche_publique&id=${imgId}`;
                    let afficheUrl = `https://archimage.efa.gr/?kroute=afficher_modele_3d&id=${imgId}`;
                    return (
                      <Vignette
                        route={afficheUrl}
                        image={imgUrl}
                        icon={<OpenInNewIcon />}
                        content={domToReact(children, options)}
                        alt="Afficher dans Archimage"
                        target="_blank"
                        color="primary"
                      />
                    );
                  }
                  case "image_request_iiif": {
                    // console.log(attribs);
                    return (
                      <TooltipArchimageIIIF
                        id={params.id}
                        text={domToReact(children, options)}
                        target={attribs.target}
                      />
                    );
                  }
                }
              if (root[1] === "image_request_iiif") {
                // console.log(children);
                return (
                  <TooltipArchimageIIIF
                    id={root[2]}
                    text={domToReact(children, options)}
                    target={attribs.target}
                  />
                );
              }

              break;
            }
            case "tholos": {
              let route = attribs.target.split("#")[1];

              switch (attribs?.type) {
                case "monument": {
                  let elem = route.split("/");
                  let last = elem[elem.length - 1];
                  return (
                    <mark className="monument-3d">
                      {domToReact(children, options)}
                    </mark>
                  );
                }
              }

              return (
                <Vignette
                  route={route}
                  icon={<AutorenewOutlinedIcon />}
                  content={domToReact(children, options)}
                  alt="Ouvrir la page du fragment"
                  target="_self"
                  color="success"
                />
              );
            }
          }
        }

        return (
          <span style={{ color: "#C2A074" }}>
            <a href={attribs.target} target="_blank">
              <mark
                style={{
                  color: "#C2A074",
                  backgroundColor: "hsla(0, 0%, 100%, 0)",
                }}
              ></mark>
            </a>
          </span>
        );
      }
    },
  };

  let parsedText = parse(text, options);
  //   console.log(parsedText);

  return <Box sx={{ p: 1 }}>{parsedText[1]}</Box>;
};

export default GalleryItem;
