function convertNotation(notation) {
  const couches = {
    _00: "iconographie",
    _ff: "materialite",
  };

  let hex = notation.slice(1, 7);
  let suffixe = notation.slice(7, 9);

  let couche = couches[`_${suffixe}`];

  return { hex: hex, couche: couche };
}

export default convertNotation;
